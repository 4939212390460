<template>
  <div
    class="p-coffeeHistory"
    :class="{ 'p-coffeeHistory--none': !hasHistory }">
    <template v-if="hasHistory">
      <div
        v-for="(dailyHistory, i) in dailyHistories"
        :key="i"
        class="p-coffeeHistory__rank c-section c-section--fluid c-section--white01Bg">
        <div
          class="c-section__inner c-section__inner--pt10 c-section__inner--pb0">
          <div class="p-coffeeHistory__date">
            {{ dailyHistory.date }}
          </div>
          <div class="c-cards c-cards--tiled">
            <div
              class="c-cards__inner"
              v-for="(item, j) in dailyHistory.items"
              :key="j">
              <CoffeeCardTiled
                :href="generateUrl(item.id)"
                :title="item.product.name"
                :description="item.product.description"
                :src="item.product.image_url"
                :barista="item.barista"
                :impression="item.is_favorable" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="nextPageUrl" class="p-coffeeHistory__actions c-section">
        <Button style-type="quaternary" fluid @click="loadMore">
          感想リストをもっと見る
        </Button>
      </div>
    </template>

    <template v-else-if="!$_loading_state">
      <Empty
        icon-name="comment"
        text="登録されている感想はありません"
        :subtext="'画面右上のQRコードリーダーを起動して\n製品バーコードやイベント会場に設置されている\nQRコードを読み取って感想登録しましょう'" />
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import { groupBy, orderBy } from 'lodash';
import { SystemDialogMessage } from '@/constants/enums';

export default {
  data: () => ({
    nextPageUrl: null,
    histories: []
  }),

  computed: {
    hasHistory() {
      return this.dailyHistories.length > 0;
    },

    /**
     * UI展開用に整形した感想リスト情報
     */
    dailyHistories() {
      // 履歴データを日付でグループ化
      const group = groupBy(this.histories, (history) =>
        moment(history.created_at).format('YYYYMMDD')
      );

      // 登録日時 降順にソート
      return orderBy(Object.entries(group), ['0'], 'desc').map((v) => ({
        date: this.$utilities.localizeDateWeek(v[0]),
        items: orderBy(v[1], ['created_at'], ['desc'])
      }));
    }
  },

  created() {
    this.fetchList();
  },

  methods: {
    async fetchList() {
      try {
        this.$_loading_start();

        const { data } = await this.$repositories(
          'products'
        ).getImpressionHistories(
          this.$utilities.getUrlSearch(this.nextPageUrl)
        );
        this.histories.push(...data.data);
        this.nextPageUrl = data.next_page_url;
      } catch {
        window.location.href = this.$systemDialog(
          SystemDialogMessage.ERROR_COMMON
        );
      } finally {
        this.$_loading_stop();
      }
    },

    async loadMore() {
      if (this.$_loading_state) return;
      await this.fetchList();
    },

    generateUrl(impressionId) {
      return this.$customUrlScheme.page(
        'detail',
        `url=/mypage/coffee-history/${impressionId}`
      );
    }
  }
};
</script>
